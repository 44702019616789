@import "../variables";

.timeline-wrapper {
}

.timeline-item {
  display: flex;
}

.timeline-left {
  position: relative;
  display: flex;
  justify-content: center;
  padding-right: 16px;
}

.employee-avatar.timeline-avatar {
  margin-top: 3px;
  z-index: 10;
  position: relative;
}
.timeline-arrow {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid $primary;
  position: absolute;
  left: 10px;
  top: 9px;
}
.timeline-border {
  position: absolute;
  border-right: solid 4px $primary;
  border-leftt: solid 4px $primary;
  border-radius: 4px;
  height: 100%;
  z-index: 1;

}

.timeline-right {
  flex: 1;
  padding: 0 16px 16px 16px;
  position: relative;
}
.timeline-card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 4px;
}
.timeline-card-meta {
  background-color: $primary;
  padding: 4px 16px;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 0.9rem;
  font-weight: 300;

}
.timeline-card-content {
  .badge {
    font-weight: 500;
  }
  .list-group-item {
    padding: 3px 6px;
  }
}
