app-team-filter {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  overflow: visible;
  z-index: 301;
  min-width: 150px;

  .ng-select {
    width: 100%;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    min-height: 31px;
  }
}