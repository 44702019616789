@import "variables";
@import "helper";
@import "parts/schedule-colors";

.bg-primary {
  color: #fff !important;
  background-color: $primary !important;
}

.bg-secondary {
  color: #fff !important;
  background-color: $secondary !important;
}

.btn {
  cursor: pointer;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.card-header:first-child.no-border-top-left {
  border-top-left-radius: 0;
}

.list-group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;

  > i.fa, > i.fas, > i.far, > i.fal, > i.fab {
    line-height: 1.5;
  }

  > i {
    margin: 0 1.25rem 0 0;
  }

  > p {
    @extend .one-liner;
    margin: 0;
    flex: 1;
  }

  > p + i {
    padding: 0 0 0 1.25rem;
    margin: 0;
  }

  > .btn {
    padding: .25rem .25rem;
    font-size: 0.5rem;
    margin-right: 0.25rem
  }

  > .input-group {
    .btn {
      padding: .25rem .25rem;
    }

    .form-control {
      padding: .25rem .25rem;
    }
  }
}
.list-group-item-clickable {
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: #f8f9fa;
  }

  &.bg-primary:hover {
    background-color: $secondary !important;
  }
}
.list-group-item-divider {
  padding: 0.25rem 1.25rem;
  background-color: #f5f5f5;
  font-weight: 600;
}
.list-group-item-toggle {
  width: 100%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.list-group-item-action {
  cursor: pointer;
}
label {
  display: inline-block;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 600;
}



.table thead th {
  font-weight: 600;
}

.table th, .table td {
  padding: 0.35rem;
  vertical-align: middle;
  font-size: 0.875rem
}

.min {
  width: 1%;
  white-space: nowrap;
}

.alert-sm {
  padding: .25rem .5rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.btn-xs, .btn-group-xs > .btn {
  height: auto;
  padding: 0.125rem 0.25rem;
  font-size: 0.8rem;
  line-height: 1;
  border-radius: 0.2rem;
}
.input-group-xs {
  .form-control, .input-group-text {
    height: calc(1em + 0.5rem + 2px);
    padding: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.2;
    border-radius: 0.2rem;
  }
}
.form-control-xs {
  height: calc(1em + 0.5rem + 2px);
  padding: 0.25rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.tooltip-inner {
  max-width: initial !important;
}


.modal-animated {
  animation-name: fade-modal;
  animation-duration: 0.3s;
}

.modal-header .close {
  outline: none;
}

@keyframes fade-modal {
  0% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1)
  }
}

.test {
  @extend .form-control;
  @extend .form-control-sm;
}
.card-toolbar {
  padding: 15px;
}

.btn.disabled {
  pointer-events: none;
}

.package-modal .modal-dialog {
  max-width: 800px;
  width: 100%;
}

a.dropdown-item {
  cursor: pointer;
  &:active {
    color: #fff;
  }
}

ion-modal {
  &.floating {
    .modal-wrapper {
      background: rgba(0, 0, 0, 0.32);
    }
  }
}

$tooltip-arrow-color: $primary;