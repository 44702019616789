
app-crud-bar {
  position: fixed;
  top: 150px;
  right: 30px;
  z-index: 100;
}

app-crud-bar + .container {
  border-right: 40px solid transparent;
}


.action-button-wrapper {
  display: flex;
  flex-direction: column-reverse;

  .btn-container {
    position: relative;
  }
  .component-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    position: relative;
  }
  .btn {
    transition: all .1s ease-out;
    width: 40px;
    height: 40px;
    //border-radius: 50px;
    text-align: center;
    margin-bottom: .5rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    position: relative;
    span {
      margin-right: 3px;
      display: inline-block;
      opacity: 0;
      transition: all .1s ease-out;
      font-size: 0;
    }

    &:hover {
      span {
        opacity: 1;
        font-size: 0.9rem;
      }
    }

    &.expandable:hover {
      width: auto;
    }
  }
}


.expand-menu {
  display: flex;
  flex-direction: column;

  &:hover {
    .btn.btn-expand {
      width: 75px;
      height: 40px;
      margin-bottom: .5rem;
      padding: 0.375rem 0.75rem;
      box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
      border: solid 1px transparent;

      span {
        opacity: 1;
        font-size: 0.8rem;
      }
    }
  }
}

.overlay-icon {
  position: absolute;
  background-color: $primary;
  border-radius: 50%;
  font-size: 0.75rem;
  right: 5px;
  top: 20px;
}

.expand-menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn.btn-expand {
  width: 0;
  height: 0;
  margin-bottom: 0;
  transition: all .1s ease-out;
  padding: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, 0);
  border: none;

  span {
    margin: 0;
    transition: all .1s ease-out;
    opacity: 0;
    font-size: 0;
  }
}

.action-button-wrapper .btn.btn-toggle {
  justify-content: center;
}

@media screen and (max-height: 730px) {
  .expand-menu {
    &:hover {
      .btn.btn-expand {
        margin-left: .5rem;
      }
    }
  }
  .expand-menu-list {
    flex-direction: row;
  }
}
.crud-tool-bar {
  display: flex;
}

.relative-export, .relative-import {

  .btn-toggle {
    height: 31px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .expand-menu-list {
    position: absolute;
    z-index: 1;
    top: 36px;
  }
  .expand-menu {
    display: flex;
    flex-direction: column;

    &:hover {
      .btn.btn-expand {
        height: 31px;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }
    }
  }
}
.fixed-export {
  .export-text {
    display: none!important;
  }
}