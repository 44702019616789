.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-content {
  position: relative;
  height: 100%;
  padding: 2px;
  white-space: normal;
  overflow-y: fixed;
  overflow-x: hidden;
  width: 400px;
  height: 200px;
  .ol-info-content {
    position: fixed;
    .ol-info-left {
      width: 200px;
      float: left;
      overflow-y: scroll;
      height: 198px;
    }
    .ol-info-right {
      width: 200px;
      height: 198px;
      float: right;
    }
  }
}
.ol-popup-content p {
  font-size: 14px;
  padding: 2px 4px;
  color: #222;
  margin-bottom: 15px;
}
.ol-popup-closer {
  position: absolute;
  top: 1%;
  right: 2%;
  font-size: 100%;
  color: $primary;
  text-decoration: none !important;
}
.ol-shift-button {
  position: absolute;
  top: 85%;
  right: 2%;
  font-size: 100%;
}
a.ol-popup-closer:hover {
  color: $primary;
  text-decoration: underline;
}
.ol-popup-closer:after {
  content: '✖';
}

.ol-info-item {
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  border-radius: 30px;
  margin-right: 12px;
  transition: 200ms;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    background-color: darken(#f8f9fa, 5%);
  }
}

.ol-info-item-title {
  font-weight: 600;
}

.ol-attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgb(204,204,204, 0.8);
  ul {
    list-style-type: none;
    padding: 0;
    display: inline;
    li {
      padding: 0 8px;
    }
  }
  button {
    display: none;
  }
}
