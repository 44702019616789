@import "../variables";

.btn.btn-sm.btn-dark.dropdown-toggle {
  &:after {
    display: none;
  }

  ~ .dropdown-menu.show {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0;

    .dropdown-item {
      font-size: .85rem;
      padding: .25rem .5rem;
      min-width: auto;
      cursor: pointer;
    }

    .dropdown-header {
      padding: .25rem .5rem;
      white-space: normal;
      font-size: .75rem;
      max-width: 150px;
    }
  }
}

