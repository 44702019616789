/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'theme/variables.scss';
@import 'theme/default.scss';
@import 'theme/map.scss';

@import 'theme/parts/loader';
@import '~bootstrap/scss/bootstrap';
@import 'theme/bootstrap-custom';

@import '~bootstrap/scss/bootstrap';
@import 'theme/bootstrap-custom';
@import 'theme/helper';
@import 'theme/dependencies';
@import 'theme/parts/crud-bar';
@import 'theme/parts/task-list';
@import 'theme/parts/dynamic-default';
@import 'theme/parts/file-upload';
@import 'theme/parts/loader';
@import 'theme/parts/radio-slider';
@import 'theme/parts/setup';
@import 'theme/parts/modal-loader';
@import 'theme/parts/log-timeline';
@import 'theme/parts/shine-buttons';
@import 'theme/parts/team-filter';

app-setup-slide-welcome, app-setup-slide-profile, app-setup-slide-invite , app-setup-slide-finish, app-setup-slide-employment, app-setup-slide-company, app-setup-slide-absence {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  ion-content {
    --background: #eef5f9;
  }
  app-header {
    z-index: 100;
  }
  app-created-hint + app-crud-tool-bar {
    hr {
      margin-top: 0;
    }
  }
  .custom-ion-modal  {
    .modal-wrapper {
      height: 200px!important;
    }
  }
