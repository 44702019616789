@import "../variables";

.btn-shine {
  position: relative;
}
.btn-shine:after {
  content: '';
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
@-webkit-keyframes pulse {
  from { background-color: rgba(255,255,255,0)}
  50% { background-color: rgba(255,255,255,0.5)}
  to { background-color: rgba(255,255,255,0)}
}
