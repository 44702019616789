@import 'variables';
@import 'parts/schedule-colors';

:root {
  --youco-medium: #{$primary};
  --youco-dark: #{$secondary};
  --youco-font: #fff;
}

/* titillium-web-200 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: local('Titillium Web ExtraLight'), local('TitilliumWeb-ExtraLight'), url('../assets/fonts/titillium-web-v8-latin-200.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* titillium-web-300 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium Web Light'), local('TitilliumWeb-Light'), url('../assets/fonts/titillium-web-v8-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url('../assets/fonts/titillium-web-v8-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url('../assets/fonts/titillium-web-v8-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* titillium-web-700 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'), url('../assets/fonts/titillium-web-v8-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* titillium-web-900 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: local('Titillium Web Black'), local('TitilliumWeb-Black'), url('../assets/fonts/titillium-web-v8-latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/titillium-web-v8-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root,
:root[mode='ios'],
:root[mode='md'] {
  --ion-font-family: 'Titillium Web', -apple-system, 'Helvetica Neue', 'Roboto', sans-serif !important;
  font-family: 'Titillium Web', -apple-system, 'Helvetica Neue', 'Roboto', sans-serif !important;
}

.toolbar {
  margin-bottom: 15px;

  .hide-on-small {
    display: none;
  }

}

.ion-text-center {
  text-overflow: inherit;
  white-space: normal;
}

.ngbToolTipClass .tooltip-inner {
  white-space: pre-wrap;
  background-color: $primary;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 55%;
}


@media (min-width: 1300px) {
  .toolbar {
    .hide-on-small {
      display: initial;
    }

    .show-on-small {
      display: none;
    }
  }
}

router-outlet.main-router {
  position: relative;
}

router-outlet.main-router ~ * {
  position: absolute;
  transition: 200ms;
  width: 100%;
}

[routerLink],
[ng-reflect-router-link] {
  cursor: pointer;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

.employee-avatar {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > i {
    color: #555;
    font-size: 1.3rem;
  }

  img {
    object-fit: cover;
    height: 35px;
    width: 35px;
  }
}

.no-results-row {
  &:hover {
    background-color: #fff !important;
  }

  img {
    max-height: 150px;
    display: block;
    margin: 0 auto;
  }
}

.filter-options-wrapper {
  height: 0;
  overflow: hidden;
  background-color: #fafafa;
  padding: 0;
  border-top: solid 1px transparent;

  &.filter-active {
    padding: 15px;
    border-top: solid 1px rgba(0, 0, 0, 0.125);
    height: auto;
    overflow: auto;
  }
}

.filtered-options {
  padding: 15px;
  border-top: solid 1px rgba(0, 0, 0, 0.125);
  height: auto;
  overflow: auto;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
}

app-page-preview {
  position: sticky;
  top: 20px;
}

.grid-options {
  .status-title {
    display: none;
  }

  .badge:hover .status-title {
  }
}

@media (min-width: 1600px) {
  .grid-options {
    .status-title {
      display: inline-block;
    }
  }
}

.month-grid-popover {
  .popover-header {
    padding: 0.3rem 0.5rem;
    font-weight: 500;
    align-items: center;
    font-size: 0.875rem;
  }

  .popover-body {
    padding: 0;

    .list-group-item {
      padding: 0.3rem 0.5rem;
    }
  }
}

.desktop-only-info {
  display: none;
}

@media screen and (max-width: 780px) {
  .desktop-only-info {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #111;
    z-index: 20000000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    h5 {
      color: #fff;
    }

    img {
      max-height: 200px;
      width: 100%;
    }
  }
}

.btn-light.btn-trash {
  border: none;
  background-color: $s-red-light;
  color: $s-red-dark;

  &:hover {
    background-color: $s-red-light;
    color: $s-red-dark;
  }
}

.log-modal {
  .modal-body {
    min-height: 100px;
    overflow: auto;
    max-height: 500px;

    &.overflow-hidden {
      overflow: hidden;
    }
  }

  .modal-dialog {
    max-width: 800px;
  }
}

.mail-modal {
  .modal-body {
    min-height: 100px;
    overflow: auto;
    max-height: 80vh;

    &.overflow-hidden {
      overflow: hidden;
    }
  }

  .modal-dialog {
    max-width: 80vw;
  }
}

.youco-tooltip {
  font-family: 'Titillium Web', sans-serif;
  font-size: 0.8rem;
}

app-work-time-detail {
  .ngb-tp {
    display: flex;
    justify-content: center;
  }

  .ngb-tp .btn.btn-link {
    display: none;
  }
}

.select-timeslot-modal {
  .modal-wrapper {
    height: 200px !important;
  }
}

.work-order-import-modal {
  .modal-wrapper {
    width: 80vw;
    height: 80vh;
  }
}

.image-modal {
  .modal-dialog.modal-dialog-centered {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .modal-content {
    background-color: transparent;
    border: none;
    display: block;
    width: auto;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    .close-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 1.5rem;
      opacity: 0.5;
      color: #fff;
      line-height: 1;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  img {
    max-width: 100%;
    width: auto;
    max-height: calc(100vh - 100px);
    height: auto;
  }
}

.key-badge-group {
}

.key-badge {
  position: relative;
  background: #e3e6e8;
  border-radius: 3px;
  border: 1px solid #c0c0c8;
  box-shadow: 0 1px rgba(0, 0, 0, 0.08);
  color: #585865;
  display: inline-block;
  font-size: 12px;
  margin: 0 13px 0 1px;
  padding: 0 4px;
  vertical-align: top;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

  &:not(:last-child):after {
    content: ' + ';
    position: absolute;
    right: -11px;
    color: $dark;
  }
}

input.form-control.is-required {
  border: solid 2px $info;

  &:focus {
    border: solid 2px $info;
    box-shadow: 0 0 0 0.2rem rgba(darken($info, 0.5), 0.25);
  }
}

input[type="color"] {
  padding: 0;
  height: 2rem;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  //border-color: 1px solid var(--ion-color-light);
}

input[type="color"]::-webkit-color-swatch {
  border-width: 0;
}

.color-picker-wrapper {
  position: relative;

  .no-color::-webkit-color-swatch {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .reset {
    color: var(--ion-color-primary-contrast);
    position: absolute;
    right: 0;
    bottom: 0;
    height: 2rem;
    background-color: var(--ion-color-primary);

    &:hover {
      cursor: pointer;
    }
  }
}

.ng-select.is-required {
  border: solid 2px $info;

  &.ng-select-opened {
    border: solid 2px $info;
    box-shadow: 0 0 0 0.2rem rgba(darken($info, 0.5), 0.25);
  }
}


.sidebar-header {
  app-filter-button {
    .btn.btn-primary {
      border: none !important;
      padding: 0 !important;
      background-color: unset !important;
    }
  }
}

.type-color-badge {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 1rem 0 0;
  border-color: transparent transparent transparent transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.team-qualification-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;

  .btn {
    pointer-events: none;
    background-color: #ccc;
    border-color: #ccc;
  }
}

.ngbToolTipClass .tooltip-inner {
  white-space: pre-wrap;
  background-color: $primary;
}

.comment-modal {
  --width: 75%;
}

app-no-result-table-row {
  width: 100%;
  display: table-row-group;
}

ion-header {
  ion-toolbar {
    --background: rgba(255, 255, 255, 0);
    --min-height: 44px;
    &:first-child {
      margin-top: 0.5rem;
    }
  }

  .toolbar-container:host {
    overflow: auto;
    position: relative;
  }

  .toolbar-title {
    overflow: visible;
  }

  ion-title {
    padding-inline: 42px;
    --padding-top: 0;
    --padding-bottom: 0;
    box-sizing: border-box;
    overflow: visible;
    height: 100%;

    .ion-text-center {
      position: relative;
      width: 100%;
      display: inline-block;
      padding-inline: 0;
    }
  }

  ion-item {
    --ion-item-background: rgba(255, 255, 255, 0);
  }
}

ion-toggle {
  height: 1.5em;
}

ion-textarea {
  &.text-area {
    --padding-start: 10px;
  }
}

.toggle-item {
  height: auto;
  overflow: visible;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  * {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &.hidden,
  &.hidden * {
    height: 0 !important;
    min-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
      pointer-events: none;
    }
  }
}

.animate {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}