// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$primary: #007991;
$secondary: #006064;
$tertiary: #00B8D4;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$dark: #222428;
$medium: #92949c;
$light: #f4f5f8;

$white: #ffffff;
$black: #000000;

$youco-primary: #136a8a;
$youco-secondary: #267871;

$teamcraft-primary: #5ab394;
$teamcraft-secondary: #007991;

$favio-primary: #F37335;
$favio-secondary: #FDC830;

$youco-light: #00B8D4;
$youco-medium: #00838F;
$youco-dark: #006064;

$s-gray-dark: #263238;
$s-gray-light: #CFD8DC;
$s-gray-medium: #52646e;
$s-blue-dark: #01579B;
$s-blue-light: #82B1FF;
$s-green-dark: #1B5E20;
$s-green-light: #B9F6CA;
$s-yellow-dark: #F57F17;
$s-yellow-light: #FFE57F;
$s-red-dark: #B71C1C;
$s-red-light: #FF8A80;

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #{$primary};
  --ion-color-primary-rgb: #{red($primary)},#{green($primary)},#{blue($primary)};
  --ion-color-primary-contrast: #{$white};
  --ion-color-primary-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-primary-shade: #{darken($primary, 3.3%)};
  --ion-color-primary-tint: #{lighten($primary, 3.3%)};

  --ion-color-secondary: #{$secondary};
  --ion-color-secondary-rgb: #{red($secondary)},#{green($secondary)},#{blue($secondary)};
  --ion-color-secondary-contrast: #{$white};
  --ion-color-secondary-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-secondary-shade: #{darken($secondary, 3.3%)};
  --ion-color-secondary-tint: #{lighten($secondary, 3.3%)};

  --ion-color-tertiary: #{$tertiary};
  --ion-color-tertiary-rgb: #{red($tertiary)},#{green($tertiary)},#{blue($tertiary)};
  --ion-color-tertiary-contrast: #{$white};
  --ion-color-tertiary-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-tertiary-shade: #{darken($tertiary, 3.3%)};
  --ion-color-tertiary-tint: #{lighten($tertiary, 3.3%)};

  --ion-color-success: #{$success};
  --ion-color-success-rgb: #{red($success)},#{green($success)},#{blue($success)};
  --ion-color-success-contrast: #{$white};
  --ion-color-success-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-success-shade: #{darken($success, 3.3%)};
  --ion-color-success-tint: #{lighten($success, 3.3%)};

  --ion-color-info: #{$info};
  --ion-color-info-rgb: #{red($info)},#{green($info)},#{blue($info)};
  --ion-color-info-contrast: #{$white};
  --ion-color-info-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-info-shade: #{darken($info, 3.3%)};
  --ion-color-info-tint: #{lighten($info, 3.3%)};

  --ion-color-warning: #{$warning};
  --ion-color-warning-rgb: #{red($warning)},#{green($warning)},#{blue($warning)};
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #{red($black)},#{green($black)},#{blue($black)};
  --ion-color-warning-shade: #{darken($warning, 3.3%)};
  --ion-color-warning-tint: #{lighten($warning, 3.3%)};

  --ion-color-danger: #{$danger};
  --ion-color-danger-rgb: #{red($danger)},#{green($danger)},#{blue($danger)};
  --ion-color-danger-contrast: #{$white};
  --ion-color-danger-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-danger-shade: #{darken($danger, 3.3%)};
  --ion-color-danger-tint: #{lighten($danger, 3.3%)};

  --ion-color-dark: #{$dark};
  --ion-color-dark-rgb: #{red($dark)},#{green($dark)},#{blue($dark)};
  --ion-color-dark-contrast: #{$white};
  --ion-color-dark-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-dark-shade: #{darken($dark, 3.3%)};
  --ion-color-dark-tint: #{lighten($dark, 3.3%)};

  --ion-color-medium: #{$medium};
  --ion-color-medium-rgb: #{red($medium)},#{green($medium)},#{blue($medium)};
  --ion-color-medium-contrast: #{$white};
  --ion-color-medium-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
  --ion-color-medium-shade: #{darken($medium, 3.3%)};
  --ion-color-medium-tint: #{lighten($medium, 3.3%)};

  --ion-color-light: #{$light};
  --ion-color-light-rgb: #{red($light)},#{green($light)},#{blue($light)};
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: #{red($black)},#{green($black)},#{blue($black)};
  --ion-color-light-shade: #{darken($light, 3.3%)};
  --ion-color-light-tint: #{lighten($light, 3.3%)};

  --ts-pending: #{$s-yellow-light};
  --ts-absence: #{$s-gray-light};
  --ts-slot-switch: #{$s-blue-light};
  --ts-confirmed: #{$s-green-light};
  --ts-denied: #{$s-red-dark};
  --ts-cancelled: #{$s-red-light};
}

@media (prefers-color-scheme: test) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: #{red($black)},#{green($black)},#{blue($black)};
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: #{red($black)},#{green($black)},#{blue($black)};
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #{$white};
    --ion-color-light-contrast-rgb: #{red($white)},#{green($white)},#{blue($white)};
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: #{red($black)},#{green($black)},#{blue($black)};

    --ion-text-color: #{$white};
    --ion-text-color-rgb: #{red($white)},#{green($white)},#{blue($white)};

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #{$white};
    --ion-text-color-rgb: #{red($white)},#{green($white)},#{blue($white)};

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }

  ion-title.title-large {
    --color: white;
  }
}
