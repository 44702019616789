@import '../variables';
.setup-bg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url('/assets/img/bg.png'), linear-gradient(to top, $teamcraft-primary, $teamcraft-secondary);
}
.setup-wrapper {
  height: 100%;
  width: 100%;
  max-width: 640px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  p,ul,li {
    font-size: 1rem;
    text-align: center;
  }
}
.setup-title {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-size: 1.4rem;
  margin-bottom: 10px;
  padding: 20px 0;
  color: #fff;
}
.setup-subtitle {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.setup-content {
  background-color: #fff;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
  padding: 15px;
  border-radius: 5px;
  flex: 1;
  overflow: auto;
  label {
    font-weight: 600;
    font-size: 0.85rem;
  }
}
.setup-buttons {
  padding: 30px 0;
  display: flex;
  justify-content: flex-end;
  .btn {
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
}
.setup-indicator {
  margin: 0 auto 10px auto;
  > div {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    opacity: 0.25;
    background-color: #fff;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: scale(1);
    margin: 0 5px;
    display: inline-block;
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
    pointer-events: none;
    &.active {
      opacity: 1;
      transform: scale(1.2);
    }
  }
}
