@import "variables";

//Google Map Styles
.gm-style {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

//Toasty Styles
@each $color, $value in $theme-colors {
  .toast-#{$color} {
    background-color: $value;
    font-size: 0.8rem;
  }
}

.toast-container .ngx-toastr {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.toast-top-center {
  top: 10px;
}
.cdk-drag {
  cursor: grab;
  &.cdk-drag-disabled {
    cursor: initial;
  }
}
//Angular Drag & Drop
.cdk-drag-preview {
  box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
  cursor: grabbing;
}

.cdk-drag-placeholder {
  opacity: 0.5;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-dragging .cdk-drag {
  transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 550ms cubic-bezier(0, 0, 0.2, 1);
}

//Freshdesk
.fd-btn-bottom a.freshwidget-theme {
  font-weight: 500 !important;
  border-color: transparent !important;
  background-color: #fff !important;
  color: #131313 !important;
  font-size: 0.85rem;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1) !important;
  padding: .25rem .5rem;
  transition: all ease-in .1s;
}

//NG-Select

.ng-select {
  @extend .form-control;
  @extend .form-control-sm;
}
.ng-select {
  height: auto;
  img {
    border-radius: 50%;
  }
  &.placeholder-hide {
    .ng-placeholder {
      display: none!important;
    }
  }
  .ng-placeholder {
    opacity: 0.75;
  }
}
.ng-select-clear.ng-select .ng-value-container .ng-value {
  color: #495057;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0;
  margin-right: 0;
  margin-bottom: 3px;

}
.ng-dropdown-panel {
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  left: 0;
}
.ng-value-icon.right {
  margin-left: 7px;
}
.ng-option:hover {
  color: #fff;
  background-color: $secondary;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.125rem 0.5rem;
  display: flex;
  align-items: center;
  &.ng-option-disabled {
    opacity: 0.5;
    color: #888;
    cursor: not-allowed;
    &:hover {
      background-color: #fff;
      color: #888;

    }
  }
}

.ng-select .ng-value-container .ng-value {
  color: #fff;
  background-color: $secondary;
  border-radius: .25rem;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0 0.25rem;
  margin-right: 0.5rem;
  align-self: center;
}
.ng-select.bg-grey {
  .ng-value-container .ng-value {
    background-color: #343a40;
  }
}

.ng-value-icon.left {
  margin-left: 0.25rem;
  float: right;
  font-size: 1rem;
  line-height: 1;
}
.ng-value-label-img-placeholder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
  display: inline-block;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
  margin-right: 5px;
}
.ng-select-disabled {
  background-color: #e6e6e6;
}
//NG Timepicker

.ngb-tp-input {
  @extend .form-control-sm;
}
.ngb-tp {
  .btn {
    padding: 0;
  }
}
.ngb-tp-input-container {
  width: auto;
}


//NGX Color-Picker

.color-picker-input {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: .25rem 0;
  display: inline-block;
}
color-picker {
  position: relative;
}
/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

// STRIPE

.StripeElement {
  @extend .form-control;
  @extend .form-control-sm;
}

input:focus,
.StripeElement--focus {
}

.StripeElement--invalid {
}

.StripeElement--webkit-autofill {
}
