@import "../variables";

.preloader {
  transition: 200ms;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 56px;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 56px);
}

.loader {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--youco-medium);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--youco-dark);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--youco-medium);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.main.menu-active {
  .preloader {
    margin-left: 0;
    width: 100%;
  }
}

#main-not-allowed, #use-chrome {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000000;
  background: url(../../assets/img/bg.png), $secondary; /* fallback for old browsers */
  background: url(../../assets/img/bg.png), -webkit-linear-gradient(to top, $primary, $secondary); /* Chrome 10-25, Safari 5.1-6 */
  background: url(../../assets/img/bg.png), linear-gradient(to top, $primary, $secondary); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
}
.not-allowed-image {
  width: 100%;
  max-width: 300px;
  height: auto;
}
