@import "../helper";
.task-list {
  .list-group-item {
    padding: 0;
  }
}
.task-name {
  @extend .one-liner;
  padding: .35rem;
  font-size: 0.75rem;
  margin: 0 auto;
}
.task-status {
  padding: .35rem;
}

.task-action {
  padding: .35rem;
}
.task-options {
  flex: 1;
}
