@import "../variables";

.hidefile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.image-upload-wrapper {
  position: relative;
  background-color: #bbb;
  background-image: -webkit-linear-gradient(45deg,rgba(0,0,0,.05) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.05) 50%,rgba(0,0,0,.05) 75%,transparent 75%,transparent);
  background-image: -o-linear-gradient(45deg,rgba(0,0,0,.05) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.05) 50%,rgba(0,0,0,.05) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(0,0,0,.05) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.05) 50%,rgba(0,0,0,.05) 75%,transparent 75%,transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding:0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &:hover {
    .image-upload-hover-icon {
      opacity: 0.75;
    }
    .image-placeholder {
      opacity: 0;
    }
  }
}
.image-upload-hover-icon {
  position: absolute;
  background-color: $secondary;
  color: #fff;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  transition: .2s;
  cursor: pointer;
}
.uploaded-image {
  max-width: 100%;
  max-height: 150px;
  height: auto;
}
.image-placeholder {
  transition: .2s;
  font-size: 2rem;
  opacity: 0.75;
}
.btn-group.file-upload-btn-group {
  .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.flex-break {
  flex-basis: 100%;
  height: 0;
}
