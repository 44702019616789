@import "../variables";

.bg-status-gray {
  background-color: $s-gray-light !important;
  border-color: $s-gray-dark !important;
  color: $s-gray-dark !important;

  &:hover {
    background-color: $s-gray-light !important;
  }
}

.bg-status-blocked {
  background-color: #ccc !important;
  color: $s-gray-dark !important;
  border-color: $s-gray-dark !important;
}

.bg-status-blue {
  background-color: $s-blue-light !important;
  border-color: $s-blue-dark !important;
  color: $s-blue-dark !important;

  &:hover {
    background-color: $s-blue-light !important;
  }
}

.bg-status-green {
  background-color: $s-green-light !important;
  border-color: $s-green-dark !important;
  color: $s-green-dark !important;

  &:hover {
    background-color: $s-green-light !important;
  }
}

.bg-status-yellow {
  background-color: $s-yellow-light !important;
  border-color: $s-yellow-dark !important;
  color: $s-yellow-dark !important;

  &:hover {
    background-color: $s-yellow-light !important;
  }
}

.bg-status-red {
  background-color: $s-red-light !important;
  border-color: $s-red-dark !important;
  color: $s-red-dark !important;

  &:hover {
    background-color: $s-red-light !important;
  }
}
